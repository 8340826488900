<template>
  <VDialog v-model="visibility" max-width="1200" scrollable content-class="cross__dialog"  :transition="$dialogTransition">
    <VCard>
      <VCardTitle>
        <VRow justify="space-between" align="center">
          <VCol :class="{'text-body-1' : $vuetify.breakpoint.sm,'text-caption' : $vuetify.breakpoint.xs}" cols="auto" class="pr-0">
           {{$t('cabinet.usersList')}}
          </VCol>
          <VSubheader class=" mr-2 text-capitalize" :class="{'text-caption' : $vuetify.breakpoint.xs}">
            {{ DateToText }}
          </VSubheader>
          <div class="cross__inner">
            <VBtn fab small elevation="0" @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </VBtn>
          </div>
        </VRow>
      </VCardTitle>
      <VCardText>
        <v-simple-table fixed-header :height="!$vuetify.breakpoint.smAndDown ? '360px' : '250px'" class="my-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-left md">
                  <VTextField
                    @keypress="validationForName($event)"
                    @paste="validationForName($event)"
                    :label="$t('form.name')"
                    outlined
                    dense
                    hide-details
                    @input="changeFilter"
                    v-model="filter.name"
                    clearable
                    color="success"
                  />
                </th>
                <th class="text-left md">
                  <VTextField
                    @keypress="validationForSurname($event)"
                    @paste="validationForSurname($event)"
                    :label="$t('form.surname')"
                    outlined
                    dense
                    hide-details
                    @input="changeFilter"
                    v-model="filter.surname"
                    clearable
                    color="success"
                  />
                </th>
                <th class="text-left">
                  <VTextField
                    @keypress="validationForEmail($event)"
                    @paste="validationForEmail($event)"
                    :label="$t('form.email')"
                    outlined
                    dense
                    hide-details
                    @input="changeFilter"
                    v-model="filter.email"
                    clearable
                    color="success"
                  />
                </th>
                <th class="text-left md">
                  <VTextField
                    @keypress="validationForPhoneNumber($event)"
                    @paste="validationForPhoneNumber($event)"
                    :label="$t('form.phone_number')"
                    outlined
                    dense
                    hide-details
                    @input="changeFilter"
                    v-model="filter.phone"
                    clearable
                    color="success"
                  />
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td v-if="user.path_img" class="text-center">
                  <v-avatar size="36">
                    <img :src="user.path_img" />
                  </v-avatar>
                </td>
                <td v-else class="text-center">
                  <v-avatar>
                    <v-icon x-large>
                      mdi-account-circle
                    </v-icon></v-avatar
                  >
                </td>
                <td>{{ user.name || "--" }}</td>
                <td>{{ user.surname || "--" }}</td>
                <td>{{ user.email || "--" }}</td>
                <td>{{ user.phone || "--" }}</td>
                <td>
                  <VBtn @click="onClientDetail(user)" small color="primary" class="white--text">
                    <v-icon left dense>mdi-chart-box-plus-outline</v-icon>
                    {{$t('form.more')}}
                  </VBtn>
                </td>
              </tr>
              <tr height="30px">
                <div
                  v-observer="{ nextPage: nextPage }"
                  class="observer"
                  v-if="!loadMoreDisabled"
                ></div>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </VCardText>
      <VCardActions> </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import moment from "moment";
import usersService from "../../services/request/users/usersService";
import paginationMixin from "../../mixins/paginationMixin";
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import { mapGetters  } from 'vuex';
import {
  uniqueByUUID,
  validationForEmail,
  validationForName,
  validationForPhoneNumber,
  validationForSurname
} from "@/mixins/helpers";
import EventBus from "../../events/EventBus";

export default {
  props: {
    visible: {
      require: true
    },
    chosenDate: {
      require: false
    }
  },
  mixins: [loader, notifications, user, paginationMixin],

  data: () => ({
    filter: {
      name: '',
      surname: '',
      email: '',
      phone: '',
    },
    users: [],
    loadMoreDisabled: false,
    date: new Date().toISOString().substr(0, 7),
    mainTotal: "",
    totalPrice: "",
    isLoadingFilter: false,
    inputFilter: {},
  }),
  watch: {
    users: {
      handler(e) {
        EventBus.$emit("deportCurrentSizeChange", true);
      }
    },
    chosenDate: {
      deep: true,
      handler() {
        // orderDialog(this.chosenDate);
        if (this.chosenDate) {
          // orderDialog(this.chosenDate);

          this.date = this.chosenDate;
        }
      }
    },
    page(e) {
      if (this.perPage * this.page >= 200 && this.perPage * this.page <= 200 + this.perPage) {
        this.pageBeforeLimit = e;

      }
    },
    filter: {
      deep: true,
      async handler() {
        const params = {
          uselike: true,
        };
        Object.keys(this.filter).forEach(key => {
          if (this.filter[key]) {
            params[key] = this.filter[key];
          }
        });
        this.inputFilter = params;
        //
        // orderDialog(this.filter)
        if (params.phone) {
          params.phone = params.phone.replace(/[^+\d]/g, '');
        }

        this.page = 1;
        await this.changeFilter(params, false, true);
      },
    },
    async perPage() {
      this.page = 1;
      await this.changeFilter();
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    ...mapGetters([
      "currentLanguage"
    ]),
    DateToText() {
      return moment(this.date)
        .locale(this.currentLanguage.key)
        .format(" MMMM YYYY");
    }
  },
  mounted() {
    this.getUsersList();
  },
  methods: {
    validationForPhoneNumber,
    validationForEmail,
    validationForSurname,
    validationForName,
    paginateItems() {
      this.firstNumber = this.perPage * (this.page - 1) + 1;
      const itemsOnPage = this.users.length;
      this.secondNumber = (this.page - 1) * this.perPage + itemsOnPage;
    },
    async changeFilter(params = {}, next = false, uselike = false, updated) {
      try {
        params.offset = updated ? 0 : (this.page - 1) * this.perPage;
        params.limit = updated ? this.largeLimit : this.perPage;
        this.loading = true;
        const newItems = await usersService.getClientsList({
          ...params,
          offset: (this.page - 1) * this.perPage,
          uselike: uselike,
        });
        //
        if (next) {
          this.users.push(...newItems);
        } else {
          this.users = newItems;
        }

        this.users = uniqueByUUID(this.users);
        this.loadMoreDisabled = newItems.length < this.perPage;
        this.setLoading(false);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.setLoading(false);
      }
    },
    async previousPage() {
      if (this.page !== 1) {
        this.page -= 1;
        this.setLoading(true);
        await this.changeFilter({}, true);
      }
    },
    async nextPage() {
      this.page += 1;
      // this.setLoading(true);
      this.isLoadingFilter = true;
      //
      await this.changeFilter(this.inputFilter, true, true);
      this.isLoadingFilter = false;
    },
    async getUsersList(params) {
      if (this.permissions.can_see_client_reports) {
        try {
          const newItems = await usersService.getClientsList({
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
          });
          if (newItems && this.users != undefined) {
            this.users.push(...newItems);
            this.loadMoreDisabled = newItems.length < this.perPage;
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    onClientDetail(e) {
      const params = [];
      params.uuid = e.uuid;
      params.date = this.chosenDate;
      this.$emit("showReportDialog", params);
    }
  }
};
</script>

<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
