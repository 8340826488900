<template>
  <div>
    <EchartVue
      :chartData="datacollection"
      :type="'day'"
      v-if="type === 'day'"
      :isLoading="loading"
    />
    <EchartVue :chartData="datacollection" :type="'month'" v-else :isLoading="loading" />
  </div>
</template>
<script>
import metricsService from "../../services/request/metrics/metricsService";
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import user from "../../mixins/user";
import language from "../../mixins/language";
import EchartVue from "./Echart.vue";
import { mapGetters } from "vuex";

const allMonths = {
  "uk":["Січень","Лютий", "Березень", "Квітень", "Травень","Червень","Липень","Серпень","Вересень","Жовтень",'Листопад','Грудень']
}

export default {
  name: "DeliveredProducts",
  props: ["id_state", "chosenDate", "type"],
  mixins: [notifications, loader, user, language],
  data: () => ({
    labels: [],
    datasets: [],
    year: new Date().toISOString().substr(0, 4),
    month: new Date().toISOString().substr(5, 2),
    datacollection: null,
    options: null,
    loading: false
  }),

  mounted() {
    this.createGraphic();
  },
  components: {
    EchartVue
  },
  watch: {
    currentLanguage(){
      this.createGraphic()
  },
    chosenDate: {
      deep: true,
      handler(e) {
        this.onSelectDate();
        //
      }
    }
  },
  methods: {
    async onSelectDate() {
      try {
        this.loading = true;
        if (this.chosenDate) {
          this.year = this.chosenDate.substr(0, 4);
          this.month = this.chosenDate.substr(5, 2);
          this.createGraphic();
        }
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    async createGraphic() {
      try {
        if (this.chosenDate) {
          this.year = this.chosenDate.substr(0, 4);
          this.month = this.chosenDate.substr(5, 2);
        }
        const params = [];
        params.id_state = 412;

        // params.uuid_user = this.getUserProperty('uuid')
        // orderDialog(params);
        this.options = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: false,
            tooltip: true
          }
        };
        if (this.type === "day") {
          params.year = this.year;
          params.month = this.month;
          const deliveries = await metricsService.getMetricsDeliveriesDelivered(params);
          // orderDialog(deliveries);

          this.$emit("total", deliveries.message);
          const labels = [];
          const data = [];
          deliveries?.object.forEach(item => {
            labels.push(item.date.substr(5));
            data.push(item.amount);
          });
          this.datacollection = {
            labels: [...labels],
            datasets: [
              {
                backgroundColor: "#4fae3b1f",
                borderColor: "#4fae3b",
                data: [...data]
              }
            ]
          };
        } else {
          params.year = this.year;
          const deliveries = await metricsService.getMonthMetricsDeliveriesDelivered(params);
          // orderDialog(deliveries);
          this.$emit("total", deliveries.message);
          const labels = [];
          const data = [];
          deliveries.object.forEach(item => {
            labels.push(item.month);
            data.push(item.amount);
          });
          const montsName = allMonths[this.currentLanguage.key];
          // if (this.$vuetify.breakpoint.smAndDown) {
          //   // montsName = this.$t('chart.all_months_small');
          // } else {
          // }
          this.datacollection = {
            labels: montsName,
            datasets: [
              {
                backgroundColor: "#4fae3b",
                data: [...data]
              }
            ]
          };
        }
      } catch (e) {
        // orderDialog(e);
        // console.log(e)
      }
    },
    alternatePointStyles() {
      return "circle";
    }
  },
    computed: {
    ...mapGetters(["currentLanguage"])
  },
};
</script>
